import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { getDefaultTheme } from '@Assets/styles/theme/theme';

export enum RowGap {
  Xs = 'xs_4',
  Small = 'small_8',
  Default = 'default_16',
  Large = 'large_32',
  Xl = 'xl_64',
}

interface Props extends PropsWithClassName {
  noGutters?: boolean;
  rowGap?: RowGap;
  columnGap?: RowGap;
  xsStart?: boolean;
  xsCenter?: boolean;
  xsEnd?: boolean;
  xsAround?: boolean;
  xsBetween?: boolean;
  xsTop?: boolean;
  xsMiddle?: boolean;
  xsBottom?: boolean;

  smStart?: boolean;
  smCenter?: boolean;
  smEnd?: boolean;
  smAround?: boolean;
  smBetween?: boolean;
  smTop?: boolean;
  smMiddle?: boolean;
  smBottom?: boolean;

  mdStart?: boolean;
  mdCenter?: boolean;
  mdEnd?: boolean;
  mdAround?: boolean;
  mdBetween?: boolean;
  mdTop?: boolean;
  mdMiddle?: boolean;
  mdBottom?: boolean;

  lgStart?: boolean;
  lgCenter?: boolean;
  lgEnd?: boolean;
  lgAround?: boolean;
  lgBetween?: boolean;
  lgTop?: boolean;
  lgMiddle?: boolean;
  lgBottom?: boolean;

  children?: React.ReactNode;
}

const classMap = {
  noGutters: 'no-gutters',
  xsStart: 'start-xs',
  xsCenter: 'center-xs',
  xsEnd: 'end-xs',
  xsAround: 'around-xs',
  xsBetween: 'between-xs',
  xsTop: 'top-xs',
  xsMiddle: 'middle-xs',
  xsBottom: 'bottom-xs',
  smStart: 'start-sm',
  smCenter: 'center-sm',
  smEnd: 'end-sm',
  smAround: 'around-sm',
  smBetween: 'between-sm',
  smTop: 'top-sm',
  smMiddle: 'middle-sm',
  smBottom: 'bottom-sm',
  mdStart: 'start-md',
  mdCenter: 'center-md',
  mdEnd: 'end-md',
  mdAround: 'around-md',
  mdBetween: 'between-md',
  mdTop: 'top-md',
  mdMiddle: 'middle-md',
  mdBottom: 'bottom-md',
  lgStart: 'start-lg',
  lgCenter: 'center-lg',
  lgEnd: 'end-lg',
  lgAround: 'around-lg',
  lgBetween: 'between-lg',
  lgTop: 'top-lg',
  lgMiddle: 'middle-lg',
  lgBottom: 'bottom-lg',
};

const Row = (props: Props): React.ReactElement => {
  const { rowGap, children, dataTestId, columnGap } = props;

  const getRowClassNames = (): string => {
    const extraClasses = ['row'];

    if (props.className) {
      extraClasses.push(props.className);
    }

    return Object.keys(props)
      .filter((key) => Object.keys(classMap).includes(key))
      .map((key) => classMap[key as keyof typeof classMap])
      .concat(extraClasses)
      .join(' ');
  };

  const theme = getDefaultTheme();

  return (
    <div
      className={getRowClassNames()}
      data-testid={dataTestId ?? 'Row-root'}
      style={{
        gap: rowGap ? theme.space[rowGap] : 'initial',
        ...(columnGap && { rowGap: theme.space[columnGap] }),
      }}
    >
      {children}
    </div>
  );
};

export { Row };
