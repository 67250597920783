import Color from 'color';

import { colorBlack, colorWhite } from '@Assets/styles/theme/theme';

export const adjustAlpha = (hexColor: string, alpha: number): string => {
  return Color(hexColor).alpha(alpha).hexa();
};

export const toHex = (color: string): string => {
  return Color(color).hex();
};

export const getContrastTextColor = (backgroundColor: string): string => {
  if (Color(backgroundColor).isDark()) {
    return colorWhite;
  }

  return colorBlack;
};

export const changeTowardsContrast = (hexColor: string, ratio: number): string => {
  const color = Color(hexColor);

  if (color.isDark()) {
    return color.mix(Color(colorWhite), ratio).hexa();
  }

  return color.mix(Color(colorBlack), ratio).hexa();
};

export const grayscaleWithAlpha = (hexColor: string): string => {
  const color = Color(hexColor);

  return color.grayscale().alpha(color.alpha()).hexa();
};

export const isValidColor = (color: string): boolean => {
  return /^#[0-9A-F]{8}$/i.test(color);
};
